/* ============== SF Pro ============== */
@font-face {
  font-family: 'SF Pro';
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Heavy'), url('../../public/fonts/SFPro/SF-Pro-Text-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Bold'), url('../../public/fonts/SFPro/SF-Pro-Text-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 600;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Semibold'), url('../../public/fonts/SFPro/SF-Pro-Text-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Medium'), url('../../public/fonts/SFPro/SF-Pro-Text-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Regular'), url('../../public/fonts/SFPro/SF-Pro-Text-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  src: local('SF Pro Light'), url('../../public/fonts/SFPro/SF-Pro-Text-Light.otf') format('opentype');
}
