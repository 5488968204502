body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: inherit !important;
}

html {
  height: 100%;
}

:root {
  --swiper-navigation-sides-offset: 2em;
  --swiper-navigation-size: 1.5em !important;
}

#root {
  height: 100%;
}

h1, h2 {
  font-family: 'legitima', serif;
}

thead > tr > th {
  font-family: var(--chakra-fonts-body) !important;
}

.helperText {
  color: var(--chakra-colors-chakra-subtle-text);
}

.reviewRsvp > *:not(:last-child) {
  margin-bottom: 1em;
}

.reviewRsvp > *:last-child {
  margin-bottom: 0.5em;
}

.homepage {
  height: 100%;
}

.rsvpContainer {
  height: 100%;
}

.banner {
  height: 100%;
  background: url('../public/images/banner.jpg') no-repeat center center fixed; 

  -webkit-background-size: 100%; 
  -moz-background-size: 100%; 
  -o-background-size: 100%; 
  background-size: 100%; 

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  color: white;
  text-shadow: 0px 0px 4em rgba(0,0,0,0.5);

  text-align: center;
}

.banner:after {
  background-color: black;
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 1.5em;
  height: 2.5em;
  margin-left: -20px;
  top: 85%;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: icon-scroll;
          animation-name: icon-scroll;
}

@-webkit-keyframes icon-scroll {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(2.5em - 24px));
  }
}

@keyframes icon-scroll {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(2.5em - 24px));
  }
}

.sections > * {
  padding-top: 10em;
}

.timeline {
  margin: 2em 0;
  list-style-type: none;
  display: flex;
  padding: 0;
  text-align: center;
  cursor: grab;
}

.timeline li {
  transition: all .2s ease;
}

.timestamp {
  width: 100%;
  margin-bottom: 20px;
  padding: 0px 40px;
}

.status {
  margin: 1.5em 0;
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 4px solid var(--chakra-colors-messenger-500);
  position: relative;
  transition: all .2s ease;
}


.status:before {
  content: '';
  width: 1em;
  height: 1em;
  background-color: snow;
  border-radius: 1em;
  border: 4px solid var(--chakra-colors-messenger-500);
  position: absolute;
  top: -15px;
  left: calc(50% - 0.5em - 2px);
  transition: all .2s ease;
}

.swiper-control {
  text-align: right;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next, .swiper-button-prev {
  border-color: var(--chakra-colors-messenger-500);
  border-width: 2px;
  background-color: snow;
  width: 3em !important;
  height: 3.5em !important;
  margin-top: calc(0px - 1.75em) !important;
  transition: all 0.2s ease;
  font-weight: bold !important;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  border-color: var(--chakra-colors-messenger-500);
  border-width: 2px;
  background-color: var(--chakra-colors-messenger-500);
  color: white;
}

.swiper-container {
  width: 100%;
  height: 250px;
  margin: 50px 0;
  overflow: hidden;
  padding: 0 20px 30px 20px;
}

.swiper-slide {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.swiper-slide:nth-child(2n) {
  width: 40%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}

.swiper-wrapper {
  display: flex;
}

@keyframes effectShine {
    100% {
        left: 125%; 
    } 
}

.active {
  color: var(--chakra-colors-messenger-500);
}

.marquee {
  margin-top: 2em;
  --gap: 2em;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee-content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: marquee-scroll 75s linear infinite;
}

@-webkit-keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.marquee-tag {
  margin: 0 .5em;
  padding: 1em;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.marquee-tag:nth-child(even) {
  transform: rotate(2.5deg);
}

.marquee-tag:nth-child(odd) {
  transform: rotate(-2.5deg);
}

.marquee-tag:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.nav {
  background-color: white;
  z-index: 9999;
}

.desktopHeader {
  border-bottom: 1px;
  border-style: solid;
  border-color: black;
}

.nav .chakra-button {
  transition: color .2s ease;
}

.nav .chakra-button:hover {
  color: var(--chakra-colors-messenger-500);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.cardOverride {
  border-width: 1px !important;
  border-color: black !important;
  box-shadow: unset !important;
  background-color: snow !important;
}

.cardOverride table, .cardOverride tbody, .cardOverride thead, .cardOverride td, .cardOverride tr, .cardOverride th {
  border-color: black;
}

.cardOverride tbody td {
  border-width: 0px !important;
}

.dividerOverride {
  border-color: black !important;
}

.mobileDrawer {
  position: absolute;
  top: 15px;
  right: 15px;
}

.rsvpForm {
  width: 100%;
  padding-bottom: 4em;
}

.eventDescription {
  margin: 0.75em 0;
}

.backHome {
  margin-left: 2em;
  margin-top: 2em;
}

.rsvpHeader {
  padding-top: 2em;
  margin-bottom: .5em;
}

.rsvpForm button {
  margin-top: .5em;
  margin-right: .5em;
}

.rsvpHeader .description {
  margin: .5em 0;
}

.rsvpForm .chakra-radio:last-of-type {
  margin-bottom: .5em;
}

.rsvpSelect {
  margin: 1em 0;
}

.rsvpSelect .guestNames .chakra-checkbox {
  border-width: 1px;
  border-radius: var(--chakra-radii-lg);
  padding: .25em .5em;
  margin: .25em 0;
}

.rsvpSelect .guestNames .chakra-checkbox:not(:last-child) {
  margin-right: .5em;
}

.rsvpArea {
  margin: 2em 0;
  padding: 1em;
  border-color: black;
  border-width: 1px;
  min-height: 100%;
  border-top-left-radius: var(--chakra-radii-lg);
  border-top-right-radius: var(--chakra-radii-lg);
  border-bottom: none;
}

.rsvpCollection hr {
  margin: 1em 0;
}

.weddingParty .person {
  transition: all .2s ease;
}

.weddingParty .person img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}

.gradient {
  position: relative;
  width: 100%;
}

.gradient:after {
  border-radius: var(--chakra-radii-lg);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8) 100%);
}

.weddingParty .person:hover {
  transform: scale(1.05);
}

@supports (-webkit-touch-callout: inherit) {
  .banner {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-button-next {
    right: 0 !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  .sections > * {
    padding-top: 6em;
  }
}

@media screen and (max-width: 768px) {
  .swiper-button-next, .swiper-button-prev {
    width: 2.5em !important;
    height: 3.5em !important;
  }

  .backHome {
    margin-left: 1em;
  }

  .rsvpArea {
    margin-top: 2em;
  }

  .rsvpHeader {
    padding-top: 1.5em;
  }

  .rsvp {
    text-align: center;
  }
}